const ethProjects = [
  {
    id: 1,
    name: 'MZKZ',
    description: 'Cryptopuzzles, Artwork and Games.',
    image:'https://mint.mzkz.xyz/static/media/miezeProfile.f4da42b5.png',
    mintDate: 'Minting Now',
    twitter: 'https://twitter.com/MZKZxyz',
    projectLink: 'https://mzkz.xyz', 
  },
  
];

export default ethProjects;